<template>
  <v-container fill-height>
    <v-card
      elevation="0"
      :height="height"
      :width="width"
    >
      <v-row
        no-gutters
        class="ma-2"
      >
        <v-col cols="10">
          <v-text-field
            v-model="search"
            dense
            outlined
            hide-details
            :label="$t('configurationSearch.searchLabel')"
            @keydown.enter="$emit('@search', { searchText: search })"
          />
        </v-col>
        <v-col cols="2">
          <v-btn
            depressed
            @click="$emit('@search', { searchText: search })"
          >
            <v-icon color="white">
              {{ $t('configurationSearch.searchIcon') }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: '64px',
    },
    width: {
      type: String,
      default: '387px',
    },
  },
  data: () => ({
    search: null,
  }),
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search = newVal;
      }
    },
  },
  beforeMount() {
    this.search = this.value;
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';
.v-btn {
  background-color: $primary-lighten1 !important;
  width: 48px !important;
  height: 48px !important;
  min-width: 48px !important;
}
</style>
